

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: purple;
  animation: pulse 2s infinite;
}

.App-header {
  background-color: #282c34;
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes piscar {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.piscante {
  animation: piscar 2.5s infinite; /* Defina a animação com duração de 1 segundo e infinita repetição */
}

.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  
}

.custom-table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table th,
.custom-table td {
  border: none;
  /* border-radius: 10px; */
}

.custom-table tbody tr {
  border-bottom: none;
}


.custom-table tbody tr:last-child td {
  border-bottom: none;
}

.table-header {
  background-color: #27315c;
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
}
.rounded-header {
  border-radius: 10px 0px 0 0;
  background-color: #e9ebf0!important;
  color:black
}
.rounded-headerplacar {
  border-radius: 0px 10px 0px 0px;
}

.body-table{
  background: rgba(0, 0, 0, 0.2)!important;
}
.robot {
  /* background: rgba(84, 103, 211, 0.5)!important;  */
  background: linear-gradient(to bottom, #1C1C1C, #363636)
}

.selected {
  transform: translateY(-5px); /* Move o botão 5 pixels para cima */
  font-weight: bold; /* Adiciona negrito ao texto do botão selecionado */
  font-size: 1.2rem; /* Aumenta o tamanho da fonte do botão selecionado */
  /* Outros estilos personalizados que você desejar */
}

@keyframes bordapiscantewarning {
  0%, 100% {
    border-color: transparent;
    box-shadow: 0 0 1.5rem rgba(255, 255, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 1.5rem rgba(255, 255, 0, 0.8);
  }
}

.blinkwarning {
  animation: bordapiscantewarning 1s infinite;
}

@keyframes bordapiscantesuccess {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 1.5rem rgba(88, 209, 18, 0.8);
    /* width: 8rem;
    height: 8rem; */
  }
  50% {
    transform: scale(1);
    opacity: 0.3;
    box-shadow: 0 0 2rem rgba(88, 209, 18, 0.8);
    /* width: 8rem;
    height: 8rem; */
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 2.5rem rgba(88, 209, 18, 0.8);
    /* width: 8rem;
    height: 8rem; */
  }
  
}

.blinksuccess {
  animation: bordapiscantesuccess 2.5s infinite;
}

@keyframes bolinhapiscantesuccess {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 1rem 2rem 1.5rem rgba(88, 209, 18, 0.8);
    width: 0.5rem;
    height: 0.5rem;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
    box-shadow: 2rem 1rem 2.5rem rgba(88, 209, 18, 0.8);
    width: 0.5rem;
    height: 0.5rem;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 2rem 1rem 3.5rem rgba(88, 209, 18, 0.8);
    width: 0.5rem;
    height: 0.5rem;
  }
  
}

.bolinhasuccess {
  animation: bolinhapiscantesuccess 2.5s infinite;
}

@keyframes bolinhapiscantewarning {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 1rem 2rem 1.5rem rgba(252, 248, 12, 0.932);
    width: 0.5rem;
    height: 0.5rem;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
    box-shadow: 2rem 1rem 2.5rem rgba(252, 248, 12, 0.932);
    width: 0.5rem;
    height: 0.5rem;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 2rem 1rem 3.5rem rgba(252, 248, 12, 0.932);
    width: 0.5rem;
    height: 0.5rem;
  }
  
}

.bolinhawarning {
  animation: bolinhapiscantewarning 2.5s infinite;
}

.game-container {
  /* background: radial-gradient(purple 1%, black); */
  background-image: 
  linear-gradient(to bottom, black 35%, #05d9e8 100%, #05d9e8 100%, black 100%);
  
  background-size: 50% 350%; /* Aumenta o tamanho verticalmente para criar o efeito de pulsação */
  animation: pulsate 3s infinite;
}

@keyframes pulsate {
  0% {
    background-position: 0% 0%; /* Posição inicial do gradiente */
  }
  50% {
    background-position: 0% 45%; /* Posição intermediária do gradiente */
  }
  100% {
    background-position: 0% 0%; /* Posição final do gradiente */
  }
}

.separator {
  height: 2px;
  backdrop-filter: blur(20px);
  width: 100%;
  /* border: 3px solid green; */
  /* background: linear-gradient(to right, rgba(224, 228, 18, 0.87), white, rgba(18, 168, 228, 0.87)); */
  background: linear-gradient(to right, #05d9e8, white, #05d9e8);
  opacity: 1;
  box-shadow: 1px 0px 4px 2px #05d9e8;
}

.borderheader {
  /* margin-bottom: 10px; */
  border-bottom: 0.15rem solid #05d9e8;
}

.back-button-container {
  display: flex;
  justify-content: flex-start;
}

.back-button {
  align-self: center;
}

